<template>
  <base-section id="products">
    <div class="text-center" v-if="$route.name === 'produk'">
    <v-chip
      v-for="data in filter"
      :key="data.flag"
      :class="data.flag === select ?'ma-2 primary':'ma-2 '"
      @click="select = data.flag"
      label
      class="mb-10"
    >
      <v-icon left color="white">{{ data.icon }}</v-icon>
      {{ data.id }}
    </v-chip>
    </div>
    <div 
    data-aos="fade-up"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="true"
    data-aos-anchor-placement="top-center"
    v-if="$route.name === 'beranda' || $route.name ===''">
    <span v-if="$vuetify.breakpoint.mdAndUp">
      <base-section-heading title="Temukan Asuransi Sesuai Kebutuhan Anda" class="text-uppercase mt-n7"/>
    </span>
    <span v-else>
      <div class="text-uppercase text-h5 text-center mt-n7">Temukan Asuransi Sesuai Kebutuhan Anda</div>
    </span>
    </div>
    <div 
    data-aos="fade-right"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="true"
    data-aos-anchor-placement="top-center">
    <v-container>
        <template>
          <v-row>
            <v-col
              cols="12"
              xs="12"
              md="12"
              sm="12"
            >
            <v-text-field
                outlined
                label="Pencarian..."
                prepend-inner-icon="mdi-magnify"
                class="rounded-xl"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
      </template>
      <template>
        <v-row>
        <v-col
          v-for="(data, i) in filtered"
          :key="i"
          cols="12"
          xs="12"
          md="6"
          sm="6"
          class="pa-1"
        >
        <v-card
          class="mx-auto cursor"
          max-width="300"
          height="75px"
        >
          <v-card-title>
          <v-avatar size="60" class="mt-n2">
           <v-img :src="require(`@/assets/${data.web_product_src_url}`)">
          </v-img>
          </v-avatar>
          <span class="body-1 mx-2">{{data.web_product_name}}</span>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-icon 
                  v-bind="attrs"
                  v-on="on"
                  @click="ListProduct(data.web_product_cob)">mdi-chevron-down</v-icon>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in product"
                  :key="index"
                  @click="Detail(item.url)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
          </v-menu>
          </v-card-title>
        </v-card>
        </v-col>
        <v-col class="text-center">
        <v-btn
          color="primary"
          @click = "GoTo"
          v-if="$route.name === 'produk-detail'"
          class = "text-capitalize"
          rounded
        >
          Produk Lainnya <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
      </v-row>
      </template>
    </v-container>
    <v-snackbar
        v-model="snackbar.value"
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
        <v-btn
          color="fourth"
          text
          v-bind="attrs"
          @click="snackbar.value = false"
        >
          Close
        </v-btn>
      </template>
      </v-snackbar>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProducts',
    data: () => ({
      select: 'semua',
      filter: [
      { id: 'Semua', flag: 'semua', icon: 'mdi-check' },
      { id: 'Asuransi', flag: '01', icon: 'mdi-label' },
      { id: 'Penjaminan', flag: '02', icon: 'mdi-fire' },
      ],
      posts: [],
      product: [],
      product_menu: false,
      snackbar: {
        value: false,
        text: ''
      },
    }),
    computed: {
      filtered () {
        const self = this;
        if (self.select === 'semua') {
          return self.posts;
        } else {
          return self.posts.filter(function(data) {
            return self.select === data.web_product_cob;
          });
        }
      }
    },
    created () {
        this.List()
    },
    methods: {
      List () {
        this.posts = [
                      {'web_product_id':'1101','web_product_name':'Penjaminan','web_product_src_url':'product-penjaminan-icon.png','web_product_cob':'11'},
                      {'web_product_id':'0101','web_product_name':'Asuransi Umum','web_product_src_url':'product-asuransi-icon.png','web_product_cob':'01'},
                      ]
        // let limits = '6'
        // if (this.$route.name === 'produk') {
        //   limits = ''
        // }
        // this.loading  = true
        // let formdata = {
        //       web_product_actived: 'Y',
        //       limit: limits,
        //       order_by: 'web_product_sort',
        //       order_type: 'ASC',
        // }
        // let param      = this.$functions.ParamPOST(formdata)
        // this.$axios.post(this.$functions.UrlPOST('apiWebProductList'),param,{
        // headers: {
        //     'Content-Type': 'text/plain; charset=ISO-8859-1'
        // }
        // })
        // .then(response => {
        //     let data = response.data
        //     if (data.length > 0) {
        //       if (data[0].feedback === 'Y') {
        //         this.posts = data
        //       } else {
        //         this.snackbar.value = true
        //         this.snackbar.text  = data[0].feedback
        //       }
        //     } else {
        //       this.posts = []
        //     }
        //     this.loading  = false
        // })
        // .catch(e => {
        //     this.snackbar.value = true
        //     this.snackbar.text  = e
        //     this.loading  = false
        // })
      },
      Detail (url) {
        this.$router.push({name: 'produk-category', params: { url: url }})
      },
      ReplaceSpace (data) {
        return data.replace('nan ', 'nan<br>')
      },
      GoTo () {
        this.$router.push({name: 'produk'})
      },
      ListProduct (id) {
        this.product = []
        if (id ==='11') {
          this.product = [
                      {'id':'1101','title':'Kontra Bank Garansi','url':'asuransi-penjaminan'},
                      {'id':'1102','title':'Surety Bond','url':'asuransi-penjaminan'},
                      {'id':'1103','title':'Custom Bond','url':'asuransi-penjaminan'},
                      ]
        } else {
          this.product = [
                      {'id':'0401','title':'Asuransi Marine Cargo','url':'asuransi-umum'},
                      {'id':'0801','title':'Asuransi Engineering','url':'asuransi-umum'},
                      {'id':'0201','title':'Asuransi Kendaraan Bermotor','url':'asuransi-umum'},
                      ]
        }
      }
    }
  }
</script>
